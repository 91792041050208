import {A1_ADEQUACY_ACTIVE_BREEDERS} from "../map/Constants";

export const DATA = 'data';
export const COUNTRY_INFO = 'countryInfo';
export const NUMBER_OF_VARIETIES_RELEASED = 'numberOfVarietiesReleased';
export const MARKET_SHARE_TOP_FOUR_SEED_COMPANIES = 'marketShareTopFourSeedCompanies';
export const MARKET_SHARE_STATE_OWNED_SEED_COMPANIES = 'marketShareStateOwnedSeedCompanies';
export const VARIETIES_RELEASED_WITH_SPECIAL_FEATURES = 'varietiesReleasedWithSpecialFeatures';
export const NUMBER_OF_ACTIVE_BREEDERS = 'numberActiveBreeders';
export const NUMBER_OF_ACTIVE_SEED_COMPANIES_PRODUCERS = 'numberActiveCompanies'
export const AVAILABILITY_OF_BASIC_SEED = 'availabilityOfBasicSeed';
export const AVERAGE_AGE_VARIETIES_SOLD = 'avgAgeVarietiesSold';
export const QUANTITY_CERTIFIED_SEED_SOLD = 'quantityCertifiedSeedSold';
export const SATISFACTION_ENFORCEMENT_SEED_LAW = 'satisfactionEnforcementSeedLaw';
export const COUNTRY_SETTINGS = 'country-settings';
export const CUSTOM_TOOLTIPS = 'custom-tooltips';
export const COUNTRIES_FILTER = 'countries';
export const SOURCE_CATEGORIES = 'source-category';
export const WP_CATEGORIES = 'wp-categories';
export const SUMMARY_INDICATORS = 'summary-indicators';
export const SUMMARY_INDICATORS_INFORMATION = 'summary-indicators-information';
export const VISIBLE_COUNTRIES = 'visible-countries';
export const DEFAULT_COUNTRY_ID = 'default-country-id';
export const WP_DOCUMENTS = 'wp-documents';
export const WP_CROPS = 'crops';
export const WP_IMAGES = 'wp-images';
export const DATA_CATEGORY = 'data-category';
export const MARKET_CONCENTRATION_HHI = 'marketConcentrationHHI';
export const EFFICIENCY_SEED_IMPORT_PROCESS = 'efficiencyOfSeedImportProcess';
export const NUMBER_VARIETIES_SOLD = 'numberVarietiesSold';
export const PERFORMANCE_SEED_TRADERS = 'performanceSeedTraders';
export const NUMBER_SEED_INSPECTORS = 'numberSeedInspectors';
export const EFFICIENCY_SEED_EXPORT_PROCESS = 'efficiencyOfSeedExportProcess';
export const VARIETY_RELEASE_PROCESS = 'varietyReleaseProcess';
export const AVAILABILITY_SEED_SMALL_PACKAGES = 'availabilitySeedSmallPackages';
export const PRICE_SEED_PLANTING = 'priceSeedPlanting';
export const AGRODEALER_NETWORK = 'agrodealerNetwork';
export const AGRICULTURAL_EXTENSION_SERVICES = 'agriculturalExtensionServices';
export const SHARE_TAB = 'share_tab'
export const SHARE_COUNTRY = 'share_country'
export const SHARE_CHART = 'share_chart'
export const CURRENT_TAB = 'current-tab';
export const SHARE_CROPS = 'share_crops';
export const SHARE_YEARS = 'share_years';
export const MAP_INDICATOR_DATA = 'map-indicator-data';
export const CROSS_COUNTRY_NUMBER_OF_ACTIVE_BREEDERS = 'numberActiveBreeders_crossCountry';
export const CROSS_COUNTRY_NUMBER_OF_VARIETIES_RELEASED = 'numberOfVarietiesReleased_crossCountry';
export const CROSS_COUNTRY_QUANTITY_CERTIFIED_SEED_SOLD = 'quantityCertifiedSeedSold_crossCountry';
export const CROSS_COUNTRY_NUMBER_OF_ACTIVE_SEED_COMPANIES = 'numberActiveCompanies_crossCountry'
export const CROSS_COUNTRY_NUMBER_VARIETIES_SOLD = 'numberVarietiesSold_crossCountry';
export const CROSS_COUNTRY_MARKET_SHARE_TOP_FOUR_SEED_COMPANIES = 'marketShareTopFourSeedCompanies_crossCountry';
export const CROSS_COUNTRY_MARKET_CONCENTRATION_HHI = 'marketConcentrationHHI_crossCountry';
export const CROSS_COUNTRY_MARKET_SHARE_STATE_OWNED_SEED_COMPANIES = 'marketShareStateOwnedSeedCompanies_crossCountry';
export const CROSS_COUNTRY_VARIETY_RELEASE_PROCESS = 'varietyReleaseProcess_crossCountry';
export const CROSS_COUNTRY_OVERALL_RATING_NATIONAL_SEED_TRADE_ASSOCIATION = 'overallRatingNationalSeedTradeAssociation_crossCountry'
export const CROSS_COUNTRY_AGRODEALER_NETWORK = 'agrodealerNetwork_crossCountry';
export const CROSS_COUNTRY_AVAILABILITY_SEED_SMALL_PACKAGES = 'availabilitySeedSmallPackages_crossCountry';
export const CROSS_COUNTRY_NUMBER_SEED_INSPECTORS = 'seedInspectorsByCountry';
export const CROSS_COUNTRY_AGRICULTURAL_EXTENSION_SERVICES = 'agriculturalExtensionServices_crossCountry'
export const RATING_GOVERNMENT_SEED_SUBSIDY_PROGRAM = "ratingGovernmentSeedSubsidyProgram_crossCountry";
export const ADEQUACY_ACTIVE_BREEDERS = 'adequacyActiveBreeders_map';
export const AVAILABILITY_BASIC_SEED = 'availabilityBasicSeed_map';
export const ADEQUACY_SEED_INSPECTION_SERVICES = 'getRatingSeedInspectors_map';
export const ADEQUACY_EXTENSION_SERVICES = "agriculturalExtensionServices_map";
export const ADEQUACY_AGRODEALER_NETWORK = "agrodealerNetwork_map";
export const SATISFACTION_VARIETY_RELEASE_PROCESS = "varietyReleaseProcess_map";
export const SATISFACTION_SEED_REGULATIONS = "satisfactionLawRegulation_map"
export const ADEQUACY_GOVERNMENT_EFFORT_COUNTERFEIT_SEED = "satisfactionGovernmentEffortCounterfeitSeed_map";
export const LENGTH_SEED_IMPORT = "daysImportProcess_map";
export const LENGTH_SEED_EXPORT = "daysExportProcess_map";
export const SATISFACTION_IMPORT = "efficiencyImportProcess_map";
export const SATISFACTION_EXPORT = "efficiencyExportProcess_map";

export const WP_CHART = 'wp-chart';
